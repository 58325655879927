body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans Condensed', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pd-20 {
  padding: 20px 40px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: black;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.35rem;
}

::-webkit-scrollbar-track {
  background: rgb(82, 89, 82);
  border-radius: 100vw;
  margin-block: 0.04rem;
}

::-webkit-scrollbar-thumb {
  background: rgb(249, 250, 249);
  border: 0.02rem solid rgb(0, 0, 0);
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(200, 200, 200);
}

